<template>
  <div>
    <div class="container-pefai-cards">
      <v-row class="padding-pefai">
        <v-col cols="12">
          <span class="text-title-card">
            Datos de contacto
          </span>
        </v-col>
      </v-row>
      <v-row class="padding-pefai">
        <v-col cols="3">
          <v-text-field
            label="Número de celular"
            outlined
            v-model="data.telefono"
            color="#00a7e4"
            maxlength="10"
            counter
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <common-email-autocomplete
            @emailUpdate="(e) => (data.correo = e)"
          ></common-email-autocomplete>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import cocheLogo from "@/assets/cocheLogo.svg";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  components: {
    CommonEmailAutocomplete,
  },
  data() {
    return {
      cocheLogo: cocheLogo,
      data: {
        telefono: "",
        correo: "",
      },
      landings: [],
      ramos: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      regimentList: ["Física", "Moral"],
      colonias: [],
    };
  },
  methods: {},
  props: {
    getInfo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getInfo(val) {
      if (val) {
        this.$emit("updateContactData", this.data);
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>
